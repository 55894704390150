<template>
    <div>
        <a-spin size="large" :spinning="spinning">
            <a-page-header title="批量新增同步企业" @back="goBack" />
        </a-spin>
        <div>
            <a-button type="primary" style="margin-top: 15px;margin-right: 10px;" @click="dialogVisib2 = true">
                <a-icon type="plus" />
                新增
            </a-button>
            <a-button type="primary" style="margin-top: 15px" @click="resetData">
                <a-icon type="redo" />
                刷新
            </a-button>
        </div>
        <a-table :rowKey="(record, index) => record.menuId" :columns="table.columns2" :data-source="table.data2"
            :pagination="pagination2" @change="pageChange2" tableLayout="fixed">
            <span slot="action" slot-scope="text, record">
                <a-button v-if="text.finish_at && !text.remark" type="link" @click="downErrorUscode(text.id)">下载结果</a-button>
            </span>
            <span slot="result" slot-scope="text, record">
                <p v-if="!text.finish_at">-</p>
                <p v-else-if="text.remark">{{ text.remark }}</p>
                <p v-else>{{ text.success_count }}条录入成功，{{ text.error_count }}条录入失败</p>
            </span>
        </a-table>
        <a-modal v-model="dialogVisib2" title="新增同步企业" :footer="null" @cancel="handleCancel" style=" margin: 0 auto">
            <span> <span style="color:red">*</span>
                上传附件：
                <a-upload :show-file-list="false" :custom-request="uploadSectionFile" :disabled="disabled"
                    :file-list="fileList" :beforeUpload="checkFileType" accept=".xls,.xlsx">
                    <el-button v-if="!isUpload"><a-icon type="upload" /> 上传统一社会信用代码清单
                    </el-button>
                    <el-button v-if="isUpload"><a-icon type="upload" /> 重新上传
                    </el-button>
                </a-upload>
                <p class="batchInfoName" style="color:#1890ff;" v-if="fileName">
                    《{{ fileName }}》
                </p>
            </span>
            <p class="batchInfo" style="margin-top: 10px;margin-bottom: 10px;margin-left: 80px;">
                支持扩展名：xls、xlsx格式文件
            </p>
            <a  style="margin-left: 80px;" href=" https://oss-workplace-prod.innoecos.cn/picture-prod/07f6435675010000_%E6%89%B9%E9%87%8F%E6%96%B0%E5%A2%9E%E5%90%8C%E6%AD%A5%E4%BC%81%E4%B8%9A%E6%A8%A1%E6%9D%BF.xlsx"
            download = "批量新增同步企业模板">  模板下载 
        </a>
            <template>
                <div style="display: flex;justify-content:end;align-items: center;margin-top: 10px;">
                    <a-button @click="handleCancel" style="margin-right: 10px;">
                        取消
                    </a-button>
                    <a-button type="primary" @click="importUsCode">确认</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>
<script>
import * as api from '@/api/enterprise/enterprise.js'
export default {
    data() {
        return {
            dialogVisib2: false,
            spinning: false,
            table: {
                columns2: [
                    {
                        title: '创建时间',
                        dataIndex: 'create_at',
                        key: 'create_at'
                    },
                    {
                        title: '创建人',
                        dataIndex: 'create_name',
                        key: 'create_name'
                    },
                    {
                        title: '完成时间',
                        dataIndex: 'finish_at',
                        key: 'finish_at',
                        customRender: (text, index) => {
                            return text ? text : '未完成'
                        }
                    },
                    {
                        title: '操作结果',
                        key: 'result',
                        scopedSlots: { customRender: 'result' }
                    },
                    {
                        title: '操作',
                        key: 'action',
                        scopedSlots: { customRender: 'action' }
                    }
                ],
                data2: [],
            },
            pagination2: {
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showQuickJumper: true,
                showTotal: (total, range) => {
                    return `共${total}条`
                }
            },
            fileObj: {},
            fileName: '',
            fileList: [],
            isUpload: false,
            disabled: false,
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        pageChange2(pageOption) {
            this.pagination2.current = pageOption.current
            this.pagination2.pageSize = pageOption.pageSize
            this.getErrorUsCodeList()
        },
        async getErrorUsCodeList() {
            let res = await api.getErrorUsCodeList({
                size: this.pagination2.pageSize,
                page: this.pagination2.current
            })
            console.log(res,'================================');
            
            this.table.data2 = res.data.list
            this.pagination2.total = res.data.total
            this.spinning = false
        },
        resetData() {
            this.spinning = true
            this.pagination2.current = 1
            this.pagination2.pageSize = 10
            this.getErrorUsCodeList()
        },
        handleCancel() {
            console.log('=============');
            this.dialogVisib2 = false;
            this.isUpload = false
            this.fileObj = {}
            this.fileName = ''
        },
        async importUsCode() {
            if (this.fileName == '') {
                this.$message.error('请先上传文件')
                return
            }
            const form = new FormData()
            form.append('file', this.fileObj.file)
            let res = await api.importUsCode(form)
            console.log(res);
            if (res.data.code === '200') {
                this.$message.success('操作成功')
                this.handleCancel()
            } else {
                this.$message.error(res.data.msg)
                this.handleCancel()
            }
            this.getErrorUsCodeList()
        },
        checkFileType(file) {
            if (
                file.type === 'application/vnd.ms-excel' ||
                file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                return true
            }
            this.$message.error('只支持扩展名：xls、xlsx格式文件')
            return false
        },
        uploadSectionFile(fileObj) {
            this.disabled = false
            this.isSuccess = false
            this.isUpload = true
            this.fileObj = fileObj;
            this.fileName = fileObj.file.name
        },
        async downErrorUscode(id) {
            try {
                const form = new FormData()
                form.append('id', id)
                const res = await api.downErrorUscode(id)
                if ('msSaveOrOpenBlob' in navigator) {
                    const url = res.data
                    const blob = new Blob([url], { type: 'application/vnd.ms-excel' })
                    let name = res.headers['content-disposition'].split('=')[1]
                    window.navigator.msSaveOrOpenBlob(blob, decodeURI(name))
                    return
                } else {
                    const url = window.URL.createObjectURL(new Blob([res.data]))
                    const link = document.createElement('a')
                    link.href = url
                    let name = res.headers['content-disposition'].split('=')[1]
                    link.setAttribute('download', decodeURI(name))
                    document.body.appendChild(link)
                    link.click()
                }
            } catch (error) {
                console.log(error)
                this.$message.error('下载模板失败')
            }
        },
    },
    created() {
        this.getErrorUsCodeList()
    }
}
</script>